import React from 'react';

import { Alert } from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from 'components/CustomButtons/Button.js';

import { FirebaseConfig } from '../config/FirebaseConfig';

const PaymentForm = props => {
	const serverUrlForCreatePaymentIntent = `https://us-central1-${FirebaseConfig.projectId}.cloudfunctions.net/stripe-createPaymentIntent`;
	const stripe = useStripe();
	const elements = useElements();
	const { amount, currency } = props;
	const [errorText, setErrorText] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);

	const getPaymentMethod = async () => {
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		});
		if (error) {
			setErrorText(error.message);
		} else {
			return paymentMethod;
		}
	};

	const handleSubmit = async event => {
		event.preventDefault();
		setIsLoading(true);
		try {
			await getPaymentMethod().then(async token => {
				await fetch(serverUrlForCreatePaymentIntent, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						amount,
						currency,
						payment_method: token.id,
					}),
				})
					.then(res => res.json())
					.then(async data => {
						if (data.success === false) {
							console.error(data.error);
						} else {
							
							if (data.paymentIntent.status === 'requires_action') {
								
								const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
									data.paymentIntent.client_secret,
								);
								if (errorAction) {
									Alert(errorAction.message);
								} else {
									setIsLoading(false);
									props.setChargeId(paymentIntent.id);
								}
							} else {
								setIsLoading(false);
								props.setChargeId(data.paymentIntent.id);
							}
						}
					})
					.catch(err => {
						console.error(err);
					});
			});
		} catch (error) {}
	};

	return (
		<form onSubmit={handleSubmit} style={{ width: '400px' }}>
			<CardElement />

			{errorText.length > 0 && <p style={{ color: 'red' }}>{errorText}</p>}

			{isLoading ? <p>Please Wait for 3DSecure Auth</p> : props.chargeId.length === 0 && (
				<Button type="submit" variant="contained" color="secondaryButton" style={{ marginTop: '20px' }}>
					Submit
				</Button>
			)}
		</form>
	);
};

export default PaymentForm;