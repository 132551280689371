export const FirebaseConfig = {
	"projectId": "nordicbaltictaxi-816c9",
	"appId": "1:493033501318:web:244444972f49a411181707",
	"databaseURL": "https://nordicbaltictaxi-816c9-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "nordicbaltictaxi-816c9.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyAm7Sq0Rmyr-OYrR4CK0neIsrNrcBMVsgY",
	"authDomain": "nordicbaltictaxi-816c9.firebaseapp.com",
	"messagingSenderId": "493033501318",
	"measurementId": "G-42Y9ZZ8W0H"
};